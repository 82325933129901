export default function Ajax(options) {
    const xhr = new XMLHttpRequest();
    xhr.open(options.method, options.url);
    xhr.responseType = options.responseType;
    xhr.send();
    xhr.onload = options.onload;
    xhr.onprogress = options.onprogress;
    xhr.onerror = options.onerror;
    return xhr;
}

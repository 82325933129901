import lightGallery from 'lightgallery';
import "lightgallery/css/lightgallery.css"

const setting = {
    licenseKey: '0000-0000-000-0000',
};

export default function initLightgallery() {
    const dom1 = document.querySelector('.photosets');
    const dom2 = document.querySelector('.masonry');
    lightGallery(dom1, setting);
    lightGallery(dom2, setting);
}

import { SetSwipeImageSize, BuildSwiper } from "./swipe.js";
import initLightgallery from "./initLightgallery.js"

import "../styles/main.less";
import "./iconfont";

import "./alpine.js"

window.onscroll = () => {
    const top = document.body.scrollTop + document.documentElement.scrollTop;
    if (top >= 100) {
        window.Alpine.store("common").isScroll = true;
    } else {
        window.Alpine.store("common").isScroll = false;
    }
}

(function () {
    setTimeout(() => {
        SetSwipeImageSize();
        BuildSwiper();
        initLightgallery();
        setTimeout(() => {
            window.Alpine.store("common").loaded = true;
        }, 300);
    }, 300);
})();

import Glide from '@glidejs/glide'
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

// 初始化幻灯片
export function BuildSwiper() {
    if (document.getElementsByClassName("glide").length < 1) return;
    const swiper = new Glide(".glide", {
        autoplay: 3000,
    });
    swiper.mount();
    return swiper;
}
// 设置幻灯片图片高度为幻灯片高度
export function SetSwipeImageSize() {
    const sc = Array.from(document.getElementsByClassName("swipe-card"));
    sc.forEach(item => {
        const img = item.getElementsByClassName("swipe-img")[0];
        const people = item.getElementsByClassName("swipe-people")[0];
        img.setAttribute("style", `--height: ${item.clientHeight}px`);
        people.setAttribute("style", `--margin-top: -${item.clientHeight * 0.1 - 20}px`);
    });
}

import Alpine from "alpinejs";
import Ajax from "./ajax";
import getQueryVariable from "./getQueryVariable";

window.Alpine = Alpine;

// 日夜切换
window.Alpine.store("darkMode", {
    on: window.localStorage.getItem("darkMode") == "true" ?? false,
    toggle() {
        this.on = ! this.on;
        window.localStorage.setItem("darkMode", this.on);
    },
});

window.Alpine.store("common", {
    isScroll: false,
    navShow: false,
    goTop() {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    },
    navToggle() {
        this.navShow = !this.navShow;
    },
});

window.Alpine.data("pagination", () => ({
    isLoading: false,
    loaded: false,
    async loadNext(e) {
        if (this.loaded) return;
        this.isLoading = true;
        const link = e.target.href;
        const ajax = Ajax({
            method: "GET",
            url: link,
            responseType: "document",
            onload: () => {
                // 获取 ajax 获取到的 dom
                const html = ajax.response;
                // 当前页面的文章列表 dom
                const currentPageMain = document.querySelector(".articles");
                // 获取新页面的下一页按钮
                const next = html.querySelector(".next");
                // 获取新页面文章列表
                const newPagePosts = Array.from(html.querySelectorAll(".posts-item"));
                // 遍历到当前页面的文章列表下层
                newPagePosts.forEach(v => currentPageMain.appendChild(v));
                window.history.pushState({}, "", link);
                // 如果存在下一页按钮
                if (next)
                {
                    e.target.href = next.href;
                } else {
                    e.target.innerHTML = "加载完成";
                    this.loaded = true;
                }
                this.isLoading = false;
            },
            onerror: () => {
                alert("加载失败，请检查网络");
                this.isLoading = false;
            }
        });
    }
}));

window.Alpine.data("go", () => ({
    url: "",
    second: 3,
    timer: undefined,
    init() {
        this.url = getQueryVariable("url");
        this.second = 3;
        setTimeout(() => {
            window.location.replace(this.url);
        }, 3000);
        this.timer = setInterval(() => {
            this.second -= 1;
            if (this.second <= 0) {
                clearInterval(this.timer);
            }
        }, 1000);
    },
}));

window.Alpine.start();
